<template>
	<div>
		<b-card
			no-body
			class="h-100">
			<b-card-body class="pb-3">
				<b-form
					@submit.prevent="uploadFile"
					@keydown="clearError($event.target.name);"
					@change="clearError($event.target.name);">
					<input-text
						id="name"
						:label="translate('name')"
						:setter-value="form.name"
						:errors="errors['name']"
						:required="true"
						type="text"
						@dataChanged="form.name = $event" />
					<slot name="label">
						<label>
							{{ translate('file') }} <span class="text-danger">*</span>
						</label>
					</slot>
					<b-form-file
						id="file"
						ref="file"
						:required="true"
						:placeholder="translate('no_file')"
						:browse-text="translate('browse')"
						:class="hasError('file') ? 'is-invalid' : ''"
						class="text-truncate"
						name="file"
						type="file" />
					<b-form-text
						id="file"
						style="margin-bottom: 15px;">
						{{ translate('max', {size: '5'}) }} {{ translate('allowed_ext', {ext: fileExt }) }}
					</b-form-text>
					<b-button
						:disabled="Object.keys(errors).length > 0 || loading"
						variant="primary"
						size="md"
						class="mr-2"
						type="submit">
						<template v-if="loading">
							<i class="fa fa-fw fa-spinner fa-pulse" />
							{{ translate('processing') }}
						</template>
						<template v-else>
							{{ translate('submit') }}
						</template>
					</b-button>
				</b-form>
			</b-card-body>
		</b-card>
	</div>
</template>
<script>

import InputText from '@/components/InputText';
import WindowSizes from '@/mixins/WindowSizes';
import {
	FILES_EXT as fileExt,
} from '@/settings/PersonalFiles';
import { UserFiles } from '@/translations';

export default {
	name: 'UploadFileForm',
	messages: [UserFiles],
	components: {
		InputText,
	},
	mixins: [WindowSizes],
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		errorsImport: {
			type: Object,
			default() {
				return {};
			},
		},
		formImport: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			form: this.formImport,
			errors: this.errorsImport,
			fileExt,
		};
	},
	watch: {
		errorsImport(value) {
			this.errors = value;
		},
	},
	methods: {
		uploadFile() {
			const formData = new FormData();
			this.form.file = undefined;
			if (typeof this.$refs.file !== 'undefined') {
				const [File] = this.$refs.file.$refs.input.files;
				this.form.file = File;
			}
			// Required fields
			formData.set('name', this.form.name);
			formData.set('file', this.form.file);
			this.fileInfoSubmit(formData);
		},
		fileInfoSubmit(formData) {
			this.$emit('requestData', formData);
		},
		hasError(field) {
			if (typeof this.errors[field] !== 'undefined') { return true; }
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
	},
};
</script>

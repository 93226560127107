<template>
	<div className="animated fadeIn">
		<b-button
			variant="primary"
			:title="translate('go_back')"
			style="margin-bottom: 10px;"
			@click="previousPath">
			<span class="fa fa-arrow-left" />
		</b-button>
		<div className="container">
			<upload-file-form
				:form-import="form"
				:errors-import="errors.errors"
				@requestData="requestUploadFile" />
		</div>
	</div>
</template>
<script>

import UserFile from '@/util/UserFile';
import {
	UserFiles,
} from '@/translations';
import UploadFileForm from './UploadFileForm';

export default {
	name: 'UploadFile',
	messages: [UserFiles],
	components: { UploadFileForm },
	data() {
		return {
			files: new UserFile(),
			form: {
				name: '',
				file: '',
			},
			alert: new this.$Alert(),
		};
	},
	computed: {
		errors() {
			return (typeof this.files.data.errors !== 'undefined') ? this.files.data.errors : {};
		},
	},
	methods: {
		requestUploadFile(formData) {
			const { userId } = this.$route.params;

			this.files.uploadFile(userId, formData).then(() => {
				this.alert.toast('success', this.translate('file_uploaded'));
				this.previousPath();
			}).catch(() => {
				this.alert.toast('error', this.translate('default_error_message'));
			});
		},
		previousPath() {
			const { userId } = this.$route.params;

			let routeName = 'UpdateCustomer';
			let routeParams = { customerId: userId };

			if (this.$route.name === 'UploadDistributorsFile') {
				routeName = 'DistributorsInformation';
				routeParams = { distributorId: userId };
			}

			this.$router.push({ name: routeName, params: routeParams });
		},
	},
};
</script>
